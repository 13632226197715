let Strings ={
    sv:{
        "Logga in" : "Logga in",
        "Registrera" : "Registrera",
        "Spela Trailer" : "Spela Trailer",
        "SkaSpelas" : "Kommande visningar",
        "RedanSpelade" : "Redan visade filmer denna termin",
        "Styrelsen" : "Styrelsen",
        "Ordförande" : "Ordförande",
        "Sekreterare" : "Sekreterare",
        "Kassör" : "Kassör",
        "Event" : "Event",
        "Filmansvarig" : "Filmansvarig",
        "Marknadsföring" : "Marknadsföring",
        "Webb" : "Webb",
        "Fel lösenord eller email." : "Fel lösenord eller email.",
        "Lösenordet behöver minst 8 karaktärer": "Lösenordet behöver minst 8 karaktärer",
        "Lösenordet måste innehålla minst en siffra" : "Lösenordet måste innehålla minst en siffra",
        "Lösenordet måste innehålla minst en stor bokstav" : "Lösenordet måste innehålla minst en stor bokstav",
        "Lösenordet måste innehålla minst en liten bokstav" : "Lösenordet måste innehålla minst en liten bokstav",
        "Fyll i hela formuläret" : "Fyll i hela formuläret",
        "Förnamn" : "Förnamn",
        "Efternamn" : "Efternamn",
        "Ange en korrekt email" : "Ange en korrekt email",
        "Kårmedlemskap" : "Kårmedlemskap",
        "ingen (men är student)"  : "ingen (men är student)" ,
        "Ej student" : "Ej student",
        "Lösenordet matchar inte!" : "Lösenordet matchar inte!",
        "Nästa" : "Nästa",
        "Logga in" : "Logga in",
        "Startsida" : "Startsida",
        "Skapa konto" : "Skapa konto",
        "Personnummer" : "Personnummer",
        "Adress" : "Adress",
        "Postnummer" : "Postnummer",
        "Stad" : "Stad",
        "Kön: " : "Kön: ",
        "Kvinna" : "Kvinna",
        "Man" :"Man",
        "icke binär":"icke binär",
        "Telefonnummer" : "Telefonnummer",
        "Föregående" : "Föregående",
        "Registrera" : "Registrera",
        "Lösenord" : "Lösenord",
        "Upprepa Lösenord" : "Upprepa Lösenord",
        "Fel lösenord eller email." : "Fel lösenord eller email.",
        "Logga in" :"Logga in",
        "Lösenord" : "Lösenord",
        "Logga ut" : "Logga ut",
        "BeskrivFilm1" : "Lönnmördaren Ladybug är fast besluten att göra ett lyckat jobb efter en rad tidigare misslyckade uppdrag. Ödet har dock andra planer, då Ladybugs senaste uppdrag ställer honom mot dödliga motståndare från hela världen - alla med liknande, om än motstridiga, mål - på världens snabbaste tåg.",
        "BeskrivFilm2" : "I tillbakablickar välkomnar den blivande författaren Jo och hennes systrar – Meg, Beth och Amy – sin nya granne, Laurie, till sin kreativa inre krets när de hjälper sin Marmee att hålla nere gården medan deras far tjänar i inbördeskriget.",
        "BeskrivFilm3" : "“The Handmaiden” är en fängslande sydkoreansk psykologisk thriller där en ung ficktjuv blir anlitad som tjänsteflicka åt en rik arvtagerska, men snart dras in i ett komplext nät av bedrägerier, förräderi och passion som utmanar alla deras lojaliteter och sanningar.",
        "BeskrivFilm4" : "Skrupelfria boxnings-marknadsförare, våldsamma bookmakers, en rysk gangster, inkompetenta amatör-rånare och förment judiska juvelerare kämpar för att spåra en ovärderlig stulen diamant.",
        "BeskrivFilm5" : "I en snar framtid, efter världsvid ödeläggelse från det stora kriget, ligger den dekadenta megastaden Neo Tokyo som byggts upp i spillrorna av den gamla världen. Kriminella gäng, polisbrutalitet, korruption och utbredd arbetslöshet gör staden till en plats i ständig oro.",
        "BeskrivFilm6" : "En gång varje termin uppfyller vi er önskning! Vilken film vill du att vi ska visa på CNEMA härnäst? Ta chansen att nominera och rösta så kanske vi visar just din film!",
        "BeskrivFilm7" : "I en nära framtid i Los Angeles lever Theodore. Med hjärtat krossat efter att ha avslutat ett långt förhållande blir han fascinerad av ett nytt, avancerat operativsystem som sägs vara både intelligent och ha ett eget medvetande, Samantha.",
        "BeskrivFilm8" : "Diktatorn Aladeen har gjort allt för att demokrati aldrig ska nå hans älskade hemland. När han reser till USA blir han kidnappad och befinner sig plötsligt på New Yorks gator, illa tvungen att leva den amerikanska drömmen.",
        "MedlemmarnasVal" : "Medlemmarnas val",
    },
    en:{
        "Logga in" : "Log in",
        "Registrera" : "Register",
        "Spela Trailer" : "Play Trailer",
        "SkaSpelas" : "Upcoming screenings",
        "RedanSpelade" : "Already shown movies this semester",
        "Styrelsen" : "The Board",
        "Ordförande" : "President",
        "Sekreterare" : "Secretary",
        "Kassör" : "Treasurer",
        "Event" : "Event",
        "Filmansvarig" : "Film Supervisor",
        "Marknadsföring" : "Marketing",
        "Webb" : "Web",
        "Fel lösenord eller email." : "Wrong password or email.",
        "Lösenordet behöver minst 8 karaktärer" : "The password needs atleast 8 characters",
        "Lösenordet måste innehålla minst en siffra" : "The password has to include atleast one number",
        "Lösenordet måste innehålla minst en stor bokstav" : "The password has to include atleast one capital letter",
        "Lösenordet måste innehålla minst en liten bokstav" : "The password has to include atleast one lowercase letter",
        "Fyll i hela formuläret" : "Fill out all the fields",
        "Förnamn" : "First name",
        "Efternamn" : "Surname",
        "Ange en korrekt email" : "Enter a valid email",
        "Kårmedlemskap" : "Student Union Membership",
        "ingen (men är student)" : "none (but am a student)",
        "Ej student" : "Not a student",
        "Lösenordet matchar inte!" : "The password doesn't match",
        "Nästa" : "Next",
        "Logga in" : "Log in",
        "Startsida" : "Homepage",
        "Skapa konto" : "Create account",
        "Personnummer" : "Personalnumber",
        "Adress" : "Address",
        "Postnummer" : "Zip code",
        "Stad" : "City",
        "Kön: " : "Gender: ",
        "Kvinna" : "Woman",
        "Man" : "Man",
        "icke binär":"Non-binary",
        "Telefonnummer" : "Phonenumber",
        "Föregående" : "Previous",
        "Registrera" : "Register",
        "Lösenord" : "Password",
        "Upprepa Lösenord" : "Repeat password",
        "Fel lösenord eller email." : "Wrong password or email.",
        "Logga in": "Log in",
        "Lösenord" : "Password",
        "Logga ut" : "Log out",
        "BeskrivFilm1" : "Five assassins aboard a swiftly-moving bullet train find out that their missions have something in common.",
        "BeskrivFilm2" : "Sisters Jo, Beth, Meg, and Amy mature from adolescence to adulthood as the United States struggles with the Civil War.",
        "BeskrivFilm3" : "In 1930s Korea, a girl is hired as a handmaiden to a Japanese heiress who lives a secluded life on a countryside estate. But the maid has a secret: She is a pickpocket recruited by a swindler to help seduce the Lady and steal her fortune.",
        "BeskrivFilm4" : "Unscrupulous boxing promoters, violent bookmakers, a Russian gangster, incompetent amateur robbers and supposedly Jewish jewelers fight to track down a priceless stolen diamond.",
        "BeskrivFilm5" : "A secret military project endangers Neo-Tokyo when it turns a biker gang member into a rampaging psychic psychopath who can only be stopped by a teenager, his gang of biker friends and a group of psychics.",
        "BeskrivFilm6" : "Every semester we make your wish come true!",
        "BeskrivFilm7" : "In the near future, a lonely writer develops an unlikely relationship with an operating system designed to meet his every need.",
        "BeskrivFilm8" : "The heroic story of a dictator who risked his life to ensure that democracy would never come to the country he so lovingly oppressed.",
        "MedlemmarnasVal" : "Members' choice",
    },

}
var Lang = "sv";
if(localStorage.getItem("Language") != null){
    Lang = localStorage.getItem("Language");
}

export function translate(S){
    if(Lang == "sv" ){
        return Strings.sv[S];
    }
    else{
        return Strings.en[S]
    }
}
export function changeLang(L){
    Lang = L;
    localStorage.setItem("Language", L);
    window.location.reload();
}
